.auth {
    text-align: center;
}
.auth-image-container{
    min-height: 100vh;
    background-image:linear-gradient(to bottom, rgba(225, 67, 71, 0.9), rgba(225, 67, 71, 0.9)),
    url(../../img/auth-bg-image.png);
    background-size: cover;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-form-container{
    min-height: 100vh;
}

@media screen and (max-width:992px) {
    .champ{
        background: red;
    }
}

