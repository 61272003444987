.facture{
    margin-top: 20%;
}
.custom-modal .modal-dialog {
    width: 800px; /* Modifier la largeur comme nécessaire */
  }

  .text-right{
    margin-left: 150px;
  }
  .modal-content{
    width: 800px;
  }
  .facturation{
    margin-left: 150px;
  }
  .text-border{
    margin-left: 60%;
    
  }
  