@media (max-width: 992px) {
    .labelSmalDevice{
        position: relative;
        left: -50px;
    }
    .onSmallDevicce{
       
        position: absolute;
        left: -50px;
    }
    .clientSmallDevice{
        position: relative;
        left: -5px;
    }

}