.admin-interface {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.admin-interface .sidebar-container {
    display: block;
    background-color: white;
    width: 18vw;
    min-width: 200px;
    min-height: 100vh;
}

.admin-interface .fixed-sidebar {
    position: fixed;
    min-height: 100vh;
    width: 18vw;
    min-width: 200px;
}

.admin-interface .body-container {
    display: inline-block;
    width: 82vw;
}

.admin-interface .body-container .fixed-header {
    height: 12vh;
}

.admin-interface .body-container .body {
    height: 88vh;
    overflow-y: scroll;
}

.btnWhatsassp{
    position: fixed;
    bottom: 20px; /* Ajustez la valeur pour régler la distance par rapport au bas de la page */
    right: 20px; 
    padding: 10px 20px; /* Marge intérieure du bouton */
    /* background-color: #E72D32; */
    /* color: white; */

 
}

@media (max-width: 960px) {

    .admin-interface .body-container {
        /* display: inline-block; */
        width: 100vw;
        /* position: fixed; */

    }

    .body-fixed{
        /* position: fixed; */
        /* background: #000; */
      }

}

@media (max-width: 960px) {

    .admin-interface .fixed-header {
        /* background: red; */
        display: flex;
        /* flex-direction: row; */
        margin-top: 10px;
        /* width: 100%; */
    }

    .icon-open-side {
        margin-top: 65px;
        margin-left: 5px;
        position: fixed;
        cursor: pointer;
    }
}

/* @media (max-width: 992px) {
    .user-info {
      display: flex;
      flex-direction: row;
      margin-top: -50px;
    }
  } */

@media (min-width: 992px) {

    .close {
        /* display: none; */
        color: red;
    }
}