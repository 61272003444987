.cardview {
    background-color: white;
    border-radius: 10px;
    font-weight: 100;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.cardview hr{
    border: rgba(0, 0, 0, 0.1) 1px solid;
}

@media (max-width: 960px) {

    .cardview {
        /* display: none; */
        /* color: red; */
        margin: 10px;
    }
}