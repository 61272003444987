.header-container{
    
}
.header{
    /* height: 70px; */
}
.contenu-header{
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
}
.header label input{
    width: 80%;
    border: none;
    outline: none;
}
.header label input::placeholder{
    font-size: 80%;
}

@media (max-width: 992px) {
    .user-info {
      /* display: none; */
      display: flex;
      flex-direction: row;
      margin-top: -20px;
      margin-right: 10px;
    }
    .disableIconOnSmallDevice{
        display: none;
    }
  }