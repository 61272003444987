.addImage{
    /* border: 1px solid red;x */
    width: 197.08px;
    height: 322px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DADADA;
    cursor: pointer;
}

.inpu1{
    width: 320px;

    /* height: 36px; */
}

.inputform{
    height: 60px;
}

.btn-photo{
    border: 1px solid #E82828;
    border-radius: 5px;
    color: #E82828;
    background: #fff;
}

.bg-btn {
    background: #E82828;
border: 1px solid #E82828;
border-radius: 5px;
height: 45px;
color: #FFFFFF;
}

.hauteurRow{
    height: 636px
}

.vectorImg{
/* background: #777879; */
/* border-radius: 6.20192px; */
/* display: block; */
/* width: 100%; */
}

.centerContenu{
    /* display: block; */
    justify-content: center;
    align-items: center;
    font-style: normal;
    text-align: center;
font-weight: 400;
font-size: 16px;
position: relative;
top: 150px;


}

.retour{
    cursor: pointer;
}

.gap{
    margin-right: 90px;
}

.imageGallerie{
    width: 197.08px;
    height: 322px;
}

.ajoutPhoto{
border: 1px solid #E82828;
border-radius: 5px;
height: 45px;
color: #E82828;
background-color: #fff;
margin-top: -20px;
}

.photos {
    width: 137.79px;
height: 225.13px;
}

/* .centerInput{
    margin-top: 250px;
} */