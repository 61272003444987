@media (min-width: 992px) {
 
    .dbtn {
      display: none;
      width: 100px;
    }
   
  
  }

