.listcard{
}
.listcard .card-container{
    width: 95%;
    height: 100%;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.listcard hr{
    border-color: rgba(0, 0, 0, 0.5);
}
.listcard .title{
    background-color: #E72D32;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 90%;
}
/* The list */
.listcard ul{
    width: 100%;
    height: 100%;
}
/* The list */
.listcard ul li{
    display: flex;
    width: 100%;
    cursor: pointer;
    margin-top: 12px;
    height: 100px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    align-items: center;
}
.listcard ul li:hover{
    background-color: #E72D32;
    color: white;
}
/* The list */
.listcard ul li div{
    width: 100%;
}
.listcard ul li img{
    width: 26px;
    height: 26px;
    border-radius: 50%;
}

.listcard .show-more{
    color: #E72D32;
    font-size: 90%;
}


