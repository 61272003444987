.btn-icon{
    width: 18px;
    height: 18px;
    border: none;
    background-size: contain;
    background-color: transparent;
}
.edit-btn-icon{
    background-image: url(../img/icons/edit.png);
}
.delete-btn-icon{
    background-image: url(../img/icons/delete.png);
}
/* For the Login and Register pages */
.icon{
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
}
.icon.phone-icon{
    background-image: url(../img/icons/phone.png);
}
.icon.password-icon{
    background-image: url(../img/icons/lock.png);
}
.icon.user-icon{
    background-image: url(../img/icons/user.png);
}
.icon.mail-icon{
    background-image: url(../img/icons/mail.png);
}
.icon.search-icon{
    width: 18px;
    height: 18px;
    background-size: contain;
    background-image: url(../img/icons/search.png);
}

/* For thre sidebar */
.sidebar .menu-icon{
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
}
.sidebar .user-icon{
    background-image: url(../img/icons/user.png);
}
.sidebar .home-icon{
    background-image: url(../img/icons/home.png);
}
.sidebar .commande-icon{
    background-image: url(../img/icons/commande.png);
}
.sidebar .users-icon{
    background-image: url(../img/icons/users.png);
}
.sidebar .paper-icon{
    background-image: url(../img/icons/paper.png);
}
.sidebar .list-icon{
    background-image: url(../img/icons/list.png);
}
.sidebar .crop-icon{
    background-image: url(../img/icons/crop.png);
}
 /* If actif */
.sidebar .user-icon.actif{
    background-image: url(../img/icons/user.actif.png);
}
.sidebar .home-icon.actif{
    background-image: url(../img/icons/home.actif.png);
}
.sidebar .commande-icon.actif{
    background-image: url(../img/icons/commande.actif.png);
}
.sidebar .users-icon.actif{
    background-image: url(../img/icons/users.actif.png);
}
.sidebar .paper-icon.actif{
    background-image: url(../img/icons/paper.actif.png);
}
.sidebar .list-icon.actif{
    background-image: url(../img/icons/list.actif.png);
}
.sidebar .crop-icon.actif{
    background-image: url(../img/icons/crop.actif.png);
}

/* For the Header */
.header canvas.icon{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px rgba(0, 0, 0,0.5) solid;
    background-position: center;
    cursor: pointer;
}
.header .notification-icon{
    background-size: 40%;
    background-image: url(../img/icons/notification.png);
}
.header .settings-icon{
    background-size: 60%;
    background-image: url(../img/icons/setting.png);
}
.header .logout-icon{
    background-size: 50%;
    background-image: url(../img/icons/log-out.png);
}

/* For the CardView */
.cardview .icon{
    background-size: contain;
    /* min-width: 50px; */
    max-width: 50px;
    display: flex;
    margin-left: 20px;
    width: 100%;
    height: 100%;
    background-position: top;
    min-width: 35px;
}
.header-commande-icon{
    /* background-image: url(../img/icons/blue-comande.png); */
    color: red;
}
.cardview .icon.header-users-icon{
    background-image: url(../img/icons/red-users.png);
}   
.cardview .icon.header-comptablite-icon{
    background-image: url(../img/icons/red-comptablite.png);
}

/* For the Listcard in Accueil */
.listcard .icon{
    content: '';
    display: inline-flex;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background-position: center;
    background-size: contain;
}
.listcard .icon.next-icon{
    background-image: url(../img/icons/red-arrow-right.png);
}

/* For the Custom Modal */
.custom-modal .icon{
    width: 22px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
}
.custom-modal .white-users-icon{
    background-image: url(../img//icons/white-users.png);
}
.custom-modal .white-close-icon{
    background-image: url(../img//icons/white-close.png);
}