.client .add-client-btn{
    background: #E72D32;
    border-radius: 4px;
    color: white;
    border: none;
    height: 40px;
    box-shadow: 0px 4px 3px rgba(128, 128, 128,0.4);
}
.client .add-client-btn:hover{
    background: white;
    color: red;
    box-shadow: 0px 4px 3px rgba(128, 128, 128,0.6);
}
.add-client-btn{
    background-color: #E72D32;
    border-radius: 10px;
    display: inline;
    color: white;
    height: 40px;
    /* width: 150px; */
    font-size: 18px;
    font-weight: bold;
    border: none;
}

@media(max-width: 400px){
   
     .hommeMesure{
        /* padding: 10px !important; */
        margin-top: 5px;
        width: 50px !important;
        font-size: 12px;
        
    }
    .hommeTitle{
        font-size: 13px;
    }
    .femmeMesure{
        width: 120px !important;
    }
  
  
 
}