.input-filtrer .search-input{
    height: 40px;
    background: #F7F7F7;
    border-radius: 10px;
    display: inline;
    border: none;
    outline: none;
}
.input-filtrer .search-input::placeholder{
    font-size: 110%;
}