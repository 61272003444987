.add-client{
    color: rgba(0, 0, 0, 0.5);
}

.add-client .title{
    font-size: 85%;
    font-weight: 600;
}
.add-client .space-client input[type="text"],.add-client .space-client input[type="number"],.add-client .space-client input[type="tel"]{
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    height: 32px;
    display: block;
}

#mesureTitle {
    width:100px;
    display: block;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    /* display: block; */
    /* height: 32px; */
    /* display: inline-block; */
}


.add-client .space-mesure input[type="number"]{
    width: 40%;
    border: 1px rgba(0, 0, 0, 0.4) solid;
    border-radius: 3px;
    height: 32px;
    display: inline-block;
}
.add-client .space-mesure span{
    width: 60%;
    display:inline-block;
}

.add-client .save-client-btn{
    background-color: #E72D32;
    border-radius: 10px;
    display: inline;
    color: white;
    height: 40px;
    border: none;
}


@media (max-width: 992px) {
    .smallDeviceform{
        /* width: 100%; */
        /* margin: 0; */
        display: none;
    }
    #mesureTitle{
        display: flex;
        justify-content: space-between;
    }
}

@media (max-width: 992px) {
    .colcommande{
      /* z-index: 2; */
        /* background-color: red;
        margin-top: 0 !important;
        padding: 0; */
    }
    
    }