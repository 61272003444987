.text-color-red{
  color:#fff !important;
  background-color: #e82828 !important;
  font-weight: bold !important;
}
.table-utils button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  opacity: 0.6;
  border: 1px solid #c4c4c4;
  color: #e82828;
  padding: 10px;
}
/* modal */
.pay{
  color: #e82828;
}
.pay:hover{
  color: #c4c4c4;
}

.table-utils-date-section label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #000000;
}

.table-utils-date-section input[type="date"] {
  border: 1px solid #c4c4c4;
  padding: 5px;
}

.status_green {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #34a853;
}

.status_gray {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #22242c;
}

.status_red {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #e82828;
}
