/* Styles for tabs in commandes component */

.nav-tabs.nav {
  /* display: block !important; */
  flex-wrap: nowrap !important;
}
.commands-tabs {
  /* border: none; */
  overflow-x: auto;
}
.imageOk{
  width: 10px;
}

.commands-tabs .nav-link {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  /* identical to box height */

  color: #22242c;
}

.commands-tabs .nav-link.active {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* color: #e82828; */
}

/* styles for readcrumb */
.app-breadcrumb {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #666666;
}

.app-breadcrumb a {
  text-decoration: none;
  color: #666666;
}

.app-breadcrumb .active,
.app-breadcrumb .active::before {
  /* color: #e82828; */
  cursor: unset;
}

.breadcrumb-item {
  cursor: pointer;
}

/* Add commandes styles */

.add-commande-container {
  /* text-align: start; */
  background: #ffffff;
  padding: 30px 40px;
}

.section-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #666666;
}

.section-content {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(116, 130, 145, 0.2);
  border-radius: 10px;
}

.modeleImage {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.modeleLabel {
  background: #e82828;
  border: 1px solid #e82828;
  border-radius: 5px;
  color: #ffffff;
  padding: 8px 20px;
  font-size: 20px;
  font-weight: bold;
}

.modeleImage input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.section-content label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

.section-content input[type="text"] {
  background: #ffffff;
  border: 1px solid #797979;
  box-shadow: 0px 3.25px 6.51px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 45px;
}

.section-content .select_client {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.7);
  box-shadow: 0px 3.25px 6.51px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  height: 45px;
}

.add-new-client {
  background: #e82828;
  box-shadow: 0px 3.25px 6.51px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: none;
  padding: auto;
  width: 50px;
  height: 45px;
}

.add-new-client svg {
  color: #ffffff;
}

.btnhover:hover{
  background-color: #e82828;
  color: #ffffff;
}

@media screen and (max-width:992px) {
  .client {
    width: 100%;
  }

  .commands-tabs {
    /* display: inline; */
    /* width: 500px; */
    /* display: flex; */


  }

  /* .add-client-btn{
    background: #000;
  } */
  .commandeItems {
    /* background: #000;
    color: wheat; */

  }
}