.parametre{
    /* width: 100vw; */
 /* height: 100vh; */
 display: flex;
 /* align-items: center; */
 justify-content: center;
 overflow: hidden;
}


.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  /* text-align: center; */
}

ul.nav {
    width: 60%;
    /* margin: 0 auto 2rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #e82828; */
    /* border-radius: 2rem; */
    padding-left: 0px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  ul.nav li {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
   
    /* border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem; */
  }
  ul.nav li:nth-child(2) {
    /* border-radius: 0;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem; */
  }
  /* ul.nav li:hover {
    background: #e82828;
  } */
  ul.nav li.active {
    background: #e82828;
    color: #fff;
  }