/* Les CDN de Google Fonts ont été ajouté dans le fichier index.html */
.white-logo-container {
  width: 350px;
  height: 350px;
  background-image: url(.././img/tayeur-logo.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.red-logo-container {
  width: 100px;
  height: 50px;
  margin: auto;
  background-image: url(.././img/tayeur-logo1.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.input-container {
  background-color: #f4f8f7;
  width: 100%;
  height: 50px;
}

.input-container input {
  background-color: #f4f8f7;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
}
.primary-btn {
  background: #e72d32;
  border-radius: 4px;
  color: white;
  width: 100%;
  border: none;
  height: 50px;
}
button.red-link {
  color: #e72d32;
  font-size: 14px;
}
.error-msg {
  color: #e72d32;
  font-size: 13px;
  font-weight: 100;
  padding: 0;
  margin: 0;
}
.add-btn-red {
  background: #e72d32;
  border-radius: 4px;
  color: white;
  border: none;
  height: 40px;
  box-shadow: 0px 4px 3px rgba(128, 128, 128, 0.4);
}
.add-btn-red:hover {
  background: white;
  color: #e72d32;
  box-shadow: 0px 4px 3px rgba(128, 128, 128, 0.6);
}
.add-btn-white {
  background: white;
  border-radius: 4px;
  color: #e72d32;
  border: none;
  height: 40px;
  box-shadow: 0px 4px 3px rgba(128, 128, 128, 0.1);
  border: 1px #e72d32 solid;
}
.add-btn-white:hover {
  background: #e72d32;
  color: white;
  box-shadow: 0px 4px 3px rgba(128, 128, 128, 0.6);
}

.rounded-10 {
  border-radius: 10px;
}

.custom-bg-light {
  background-color: #f5f5f5;
}
.min-height-100 {
  min-height: 100vh;
}
.min-height-max {
  min-height: 100%;
}
.min-width-100 {
  min-width: 100vw;
}

/* For the ScrollBars */
*::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(180, 180, 180, 0.8);
  border-radius: 5px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #b4b4b4;
  border-radius: 5px;
}

/* input additional styles */
input:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
