.sidebar .item{
    height: 45px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.sidebar p.actif{
    color: #E72D32;
    border-right: 5px solid #E72D32;
}

.nav-menu{
  width: 45vw;
  z-index: 999;
} 
.sidebar-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} 

.red-logo-container {}
.sidebar-fixed{
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: white;
  left: 0;
  top: 0;
  z-index: 9999999;;
}
@media (min-width: 992px) {
 
    .sidebar-close-btn {
      display: none;
    }
   
  
  }

@media (max-width: 992px) {
    .sidebar-close-btn {
      
      position: relative;
      top: -15px;
      left: -10px;
    }
    .log{
     

      width: 500px;
   
    }

    
   
  }